import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pText: {
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '20px',
    letterSpacing: '-0.24px'
  },
  bigIconDiv: { textAlign: 'center', top: '25%', alignItems: 'center' },

  divText: { margin: 'auto', width: '19rem' },

  bigIconImg: {
    width: '145px'
  },
  container: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  divCenter: {
    textAlign: 'center'
  },
  allPage: {
    height: '100%',
    textAlign: 'center',
    '&:before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%'
    }
  }
});

const WarningMessageNonIos = () => {
  const classes = useStyles();
  return (
    <div className={classes.allPage}>
      <div className={classes.container}>
        <div className={classes.bigIconDiv}>
          <img className={classes.bigIconImg} src={`./imageLogo/apple-devices.png`} alt='Apple Devices'></img>
        </div>
        <div className={classes.divText}>
          <p className={classes.pText}>Built for iPhone and iPad.</p>
          <p className={classes.pText}>
          The Ambassador Connect Gallery uses AR to let you experience every photo as a framed picture in augmented reality.</p>
          <p className={classes.pText}>Please use your iPhone or iPad to access the Gallery by scanning the QR code you have received from us.</p>
  
        </div>
      </div>
    </div>
  );
};

export default WarningMessageNonIos;
