import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pText: {
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '20px',
    letterSpacing: '-0.24px'
  },
  bigIconDiv: { textAlign: 'center', top: '25%', alignItems: 'center' },

  divText: { margin: 'auto', width: '19rem' },

  bigIconImg: {
    fontSize: '100px'
  },
  container: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  divCenter: {
    textAlign: 'center'
  },
  allPage: {
    height: '100%',
    textAlign: 'center',
    '&:before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%'
    }
  }
});

const WarningMessageQRCode = () => {
  const classes = useStyles();
  return (
    <div className={classes.allPage}>
      <div className={classes.container}>
        <div className={classes.bigIconDiv}>
          <span className={classes.bigIconImg}>&#128580;</span>
        </div>
        <div className={classes.divCenter}>
          <h3>Oops.</h3>
        </div>
        <div className={classes.divText}>
          <p className={classes.pText}>Looks like you’re trying to enter the Ambassador Connect Gallery.</p>
          <p className={classes.pText}>Please use your iPhone or iPad to access the Gallery by scanning the QR code you have received from us.</p>
          <p className={classes.pText}>Thank you!</p>
        </div>
      </div>
    </div>
  );
};

export default WarningMessageQRCode;
