import data from '../pictureList.json';

const load = () => {
    return data.pictures;
    // return shuffle(data.pictures);
}

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};


export {
    load
};