import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import * as FirebaseService from '../services/firebase-firestore-service';

import Picture from '../components/Picture';

const PictureScreen = () => {
  let { pictureId } = useParams();
  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    FirebaseService.init();
  }, []);

  useEffect(() => {
    // Hide the picture Id parameter
    window.history.replaceState(null, null, process.env.PUBLIC_URL);
  }, [pictureId]);

  const handleVote = (pictureId) => {
    // Check if vote already in cookies
    if (cookies.get(pictureId)) {
      // Remove vote from cookies
      cookies.remove(pictureId);
      // Remove vote in DB
      FirebaseService.removeVote('pictures_post_event', pictureId).then(() => console.log('Vote removed'));
    } else {
      // Add pictureId in Cookies
      cookies.set(pictureId, true);
      // Add vote in DB
      FirebaseService.addVote('pictures_post_event', pictureId).then(() => console.log('Voted'));
    }
  };

  return (
    <Picture
      pictures={history.location.state?.pictures}
      pictureId={pictureId}
      handleVote={handleVote}
      heartFilledValue={cookies.get(pictureId) ? true : false}
    />
  );
};

export default PictureScreen;