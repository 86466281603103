import firebase from "firebase/app";
import '@firebase/firestore';

const _firebaseConfig = {
  apiKey: "AIzaSyBLdI5Pha-VzK5CxKv9Bht70KNO6bxhIO4",
  authDomain: "inr-sales-talents.firebaseapp.com",
  projectId: "inr-sales-talents",
  storageBucket: "inr-sales-talents.appspot.com",
  messagingSenderId: "1078801239824",
  appId: "1:1078801239824:web:e3bb8551d7bfd51675df95"
};

const init = () => {
  return (!firebase.apps.length) ? firebase.initializeApp(_firebaseConfig) : firebase.app();
};
  
const addVote = async (collection, pictureId) => {
  return firebase.firestore().collection(collection).doc(pictureId).set({ votes: firebase.firestore.FieldValue.increment(1) }, {merge: true});
};

const removeVote = async (collection, pictureId) => {
  return firebase.firestore().collection(collection).doc(pictureId).update({ votes: firebase.firestore.FieldValue.increment(-1) });
};

const orderBy = async (collection, orderField, orderDirection='asc', limit=10) => {
  return await firebase.firestore()
    .collection(collection)
    .orderBy(orderField, orderDirection)
    .limit(limit)
    .get();
}

export {
  init,
  addVote,
  removeVote,
  orderBy
};
