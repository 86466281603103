import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  heartStyle: {},
  galleryPicture: {
    width: '100%',
    maxHeight: '100%'
  },
  galleryVote: {
    zIndex: '10',
    position: 'absolute',
    backgroundColor: 'red',
    bottom: '1.75rem',
    right: '1.75rem'
  },
  galleryImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
  },
  square: {
    float: 'left',
    position: 'relative',
    width: '33%',
    paddingBottom: '33%',
    marginBottom: '0.5%',
    overflow: 'hidden',
    backgroundImage: (pictureId) => `url('${process.env.PUBLIC_URL}/images/thumbnails/thumbnail_${pictureId}.jpg')`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '&:nth-child(3n+2)': {
      marginLeft: '0.5%',
      marginRight: '0.5%'
    }
  },
  content: {
    position: 'absolute',
    height: '90%',
    width: '90%',
    padding: '5%'
  }
});

const Thumbnail = ({ pictures, pictureId }) => {
  const classes = useStyles(pictureId);
  const history = useHistory();
  return (
    <div className={classes.square}>
      <div className={classes.content} onClick={() => history.push({pathname:`/gallery/${pictureId}`, state: {pictures: pictures}})}></div>
    </div>
  );
};

export default Thumbnail;
