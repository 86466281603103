import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainScreen from './pages/MainScreen';
import GalleryScreen from './pages/GalleryScreen';
import PictureScreen from './pages/PictureScreen';
import ARBannerButtonScreen from './pages/ARBannerButtonScreen';
import TopVotedScreen from './pages/TopVotedScreen';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path='/' component={MainScreen} />
        <Route exact path='/gallery' component={GalleryScreen} />
        <Route exact path='/gallery/:pictureId' component={PictureScreen} />
        <Route exact path='/_top/' component={TopVotedScreen} />
        <Route path='/cta/:voted' component={ARBannerButtonScreen} />
      </Switch>
    </Router>
  );
};

export default App;
