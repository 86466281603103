import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import LazyLoad from 'react-lazyload';
import { Cookies } from 'react-cookie';

import * as Pictures from '../services/load-pictures-service';
import Thumbnail from '../components/Thumbnail';

const useStyles = createUseStyles({
  titleGallery: {
    fontSize: '1.5rem',
    boxSizing: 'content-box',
    padding: '1rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divTitle: {
    height: '4rem',
    backgroundColor: '#F6F7F8'
  },
  lazyLoad: {  
    '&:nth-child(3n+2) > div': {
      marginLeft: '0.5%',
      marginRight: '0.5%'
    }
  }
});

const GalleryScreen = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const classes = useStyles();
  
  let loadedPictures = Pictures.load();
  if(!window.sessionStorage.getItem('pictures')) {
    window.sessionStorage.setItem('pictures', JSON.stringify(loadedPictures));
  } else {
    loadedPictures=JSON.parse(window.sessionStorage.getItem('pictures'));
   } 

  // const [pictures] = useState((history.location.state?.pictures) ? history.location.state?.pictures : Pictures.load());
  // const [pictures] = useState(loadedPictures);
  const [pictures] = useState(Pictures.load());


  // Check if already initialised
  useEffect(() => {
    if(!cookies.get('acgInitialised'))
      history.push('/')
  },[cookies]);

  return (
    <div>
      <div className={classes.divTitle}>
        <div className={classes.titleGallery}>Ambassadors Connect 2021</div>
      </div>
        <div>
          {pictures.map((picture) => (
            <LazyLoad once className={classes.lazyLoad} key={picture.id}>
              <Thumbnail
                key={picture.id}
                pictures={pictures}
                pictureId={picture.id}
              />
            </LazyLoad>
          ))}
        </div>
    </div>
  );
};

export default GalleryScreen;
