import { useState, useEffect } from 'react';
import { createUseStyles } from "react-jss";

import * as FirebaseService from '../services/firebase-firestore-service';
import * as Pictures from '../services/load-pictures-service';

const useStyles = createUseStyles({
    "@global": {
      table: {
        border: "1px solid black",
        borderCollapse: 'collapse'

      },
      th: {
        border: "1px solid black",
        borderCollapse: 'collapse'
      },
      tr: {
        border: "1px solid black",
        borderCollapse: 'collapse'
      }
    }
  });

const TopVotedScreen = () => {
    useStyles();
    const [mostVoted, setMostVoted] = useState([]);
    const allPictures = Pictures.load();


    useEffect(() => {
        FirebaseService.init();
        FirebaseService.orderBy('pictures_post_event', 'votes', 'desc', 10)
                        .then(
                            (picturesMostVoted) => {
                                setMostVoted(picturesMostVoted.docs);
                            }
                        );
    }, []);


    
    return (
        <>
            <h1 style={{textAlign: "center"}}>TOP 10 MOST VOTED</h1>
            <table style={{margin: "0 auto"}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Original Name</th>
                        <th>Picture</th>
                        <th>Votes</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        mostVoted && mostVoted.map(picture=>{
                            let original_name = allPictures.filter(pic => pic.id === picture.id)[0].original_name;
                            return(
                                <tr key={picture.id}>
                                    <th>{picture.id}</th>
                                    <th>{original_name}</th>
                                    <th><img src={`${process.env.PUBLIC_URL}/images/thumbnails/thumbnail_${picture.id}.jpg`} alt={picture.id} width="100px"/></th>
                                    <th>{picture.data().votes}</th>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

export default TopVotedScreen;