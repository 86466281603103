import { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { isMobileSafari } from 'react-device-detect';
import { Cookies } from 'react-cookie';

import WarningMessageQRCode from '../components/WarningMessageQRCode';
import WarningMessageNonIos from '../components/WarningMessageNonIos';
import Instructions from '../components/Instructions';



const MainScreen = () => {
    const location = useLocation();
    const history = useHistory();
    const cookies = new Cookies();
    const [isComingFromQR] = useState(((location.search === '?qr') || cookies.get('fromQR')) ? true : false);
    const [isInitialised] = useState(cookies.get('acgInitialised'));

    useEffect(() => {
        if (isInitialised) {
            history.push('/gallery');
        }

    }, [isInitialised]);
    
    useEffect(() => {
        // Store cookie for next session
        if (isComingFromQR && !cookies.get('fromQR'))
            cookies.set('fromQR', true);

        // Hide QR code validation parameter
        window.history.replaceState(null, null, process.env.PUBLIC_URL);
    }, [isComingFromQR]);

    const handleAcceptInstructions = () => {
        cookies.set('acgInitialised', true);
        history.push('/gallery');
    };

    return (
        <>
            {(!isComingFromQR && isMobileSafari) && <WarningMessageQRCode />}
            {(!isMobileSafari) && <WarningMessageNonIos />}
            {(!isInitialised && isComingFromQR && isMobileSafari) && <Instructions handleAcceptInstructions={handleAcceptInstructions}/>}
        </>

    );
    
}

export default MainScreen;