import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack, IoIosHeartEmpty, IoIosHeart } from 'react-icons/io';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  heartStyle: {
    color: '#0A7AFF'
  },
  pictureImage: {
    width: '100%'
  },
  galleryHeart: {
    paddingTop: '0.1rem'
  },
  divPicture: {
    height: '100%',
    '&:before': {
      content: '""',
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle'
    }
  },
  divImage: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  buttonGallery: {
    fontSize: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0A7AFF'
  },
  arrowStyle: {
    position: 'relative',
    top: '3px'
  },
  divHeaderFooter: {
    position: 'fixed',
    width: '100%',
    height: '2.5rem',
    backgroundColor: '#F6F7F8',
    paddingTop: '0.5rem'
  },
  divHeader: {
    top: '0'
  },
  divFooter: {
    bottom: '0',
    textAlign: 'center'
  }
});

const Picture = ({ pictures, pictureId, handleVote, heartFilledValue }) => {
  const classes = useStyles();
  const history = useHistory();
  const [heartFilled, setHeartFilled] = useState(heartFilledValue);

  const handleVoteHeart = (pictureId) => {
    setHeartFilled(!heartFilled);
    handleVote(pictureId);
  };

  const handleVoteHeartInAR = (event) => {
    if (event.data === '_apple_ar_quicklook_button_tapped') {
      // handle the user tap.
      handleVoteHeart(pictureId);
    }
  };

  useEffect(() => {
    document
      .getElementById(`ar-link-${pictureId}`)
      .addEventListener('message', handleVoteHeartInAR);
  });

  return (
    <div className={classes.divPicture}>
      <div className={`${classes.divHeaderFooter} ${classes.divHeader}`}>
        <button className={classes.buttonGallery} onClick={() => history.push({ pathname: '/gallery', state: { pictures: pictures}})}>
          <IoIosArrowBack className={classes.arrowStyle} />
          Back to Gallery
        </button>
      </div>
      <div className={classes.divImage}>
        <a
          rel='ar'
          id={`ar-link-${pictureId}`}
          // href={`.${process.env.PUBLIC_URL}/models/${pictureId}.usdz#custom=${window.location.origin}/cta/${heartFilled}/index.html`}
          href={`${process.env.PUBLIC_URL}/models/${pictureId}.usdz`}
        >
          <img className={classes.pictureImage} src={`${process.env.PUBLIC_URL}/images/${pictureId}.jpg`} alt={pictureId} />
        </a>
      </div>
      <div className={`${classes.divHeaderFooter} ${classes.divFooter}`}>
        <div className={classes.galleryHeart} onClick={() => handleVoteHeart(pictureId)}>
          {heartFilled ? (
            <IoIosHeart size='1.75em' className={classes.heartStyle} />
          ) : (
            <IoIosHeartEmpty size='1.75em' className={classes.heartStyle} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Picture;
