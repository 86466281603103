import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  pText: {
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '20px',
    letterSpacing: '-0.24px'
  },
  bigIconDiv: { textAlign: 'center' },
  smallIconDiv: { position: 'relative', display: 'inline', top: '3px' },
  smallIconImg: { width: '20px' },
  divText: {
    width: '19rem',
    textAlign: 'center',
    margin: '0 auto',
    position: 'relative',
    top: '-1rem'
  },
  cookiesText: {
    fontSize: '0.5rem',
    color: 'rgba(60,60,67,0.6)',
    margin: 'auto',
    textAlign: 'center',
    letterSpacing: 0,
    width: '22rem',
    marginBottom: '0.25rem'
  },
  aLink: {
    color: 'black',
    textDecoration: 'none',
    '&:visited': {
      color: 'black',
      textDecoration: 'none'
    }
  },
  bigIconImg: {
    width: '90px'
  },
  buttonDiv: {
    textAlign: 'center',
    paddingBottom: '0.75rem',
    paddingTop: '0.75rem'
  },
  button: {
    height: '3rem',
    width: '19rem',
    backgroundColor: '#0A7AFF',
    color: 'white',
    borderRadius: '0.8rem',
    borderColor: 'transparent',
    fontSize: '16px'
  },
  title: {
    letterSpacing: '0.35px',
    fontSize: '22px',
    textAlign: 'center',
    lineHeight: '28px',
    fontWeight: 'bold',
    margin: '1rem',
    paddingTop: '1rem'
  },
  allPage: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mainContent: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footer: {
    paddingBottom: '1rem'
  }
});

const Instructions = ({ handleAcceptInstructions }) => {
  const classes = useStyles();
  return (
    <div className={classes.allPage}>
      <header>
        <h1 className={classes.title}>Memories of Ambassadors Connect 2021. Thank you.</h1>
      </header>
      <main className={classes.mainContent}>
        {/* <div>
          <div className={classes.bigIconDiv}>
            <img
              className={classes.bigIconImg}
              src={`./imageLogo/heart_icon.png`}
              alt='Heart Icon'
            ></img>
          </div>
          <div className={classes.divText}>
            <p className={classes.pText}>
              Vote for your favourite photos by tapping the heart icon. You can vote for as many photos as you like.
            </p>
          </div>
        </div> */}
        <div className={classes.bigIconDiv}>
          <img className={classes.bigIconImg} src={`./imageLogo/ar_icon.png`} alt='AR Icon'></img>
        </div>
        <div className={classes.divText}>
          <br/>
          <p className={classes.pText}>
          Tapping inside the picture (or the AR icon in the top right corner) will let you enjoy any photo as a framed picture in augmented reality.
          </p>
        </div>
      </main>
      <footer className={classes.footer}>
        <div>
          <p className={classes.cookiesText}>
            By tapping “Accept and Continue” you agree not to download, save, or reproduce any images from this gallery or use them for personal, editorial, or commercial purposes.
          </p>
          </div>
        <div className={classes.buttonDiv}>
          <button className={classes.button} onClick={() => handleAcceptInstructions()}>
            Accept and Continue
          </button>
        </div>
        <div>
          <p className={classes.cookiesText}>
            We use cookies to give you the best online experience.<br/>For more information visit our <a className={classes.aLink} href='https://www.apple.com/legal/privacy/en-ww/'>Privacy Policy</a>.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Instructions;
