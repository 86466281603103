import { IoIosHeartEmpty, IoIosHeart } from 'react-icons/io';
import { useParams } from "react-router-dom";


const ARBannerButtonScreen = () => {
    const { voted } = useParams();

    return (

        <div id="wrapper" style={{width: '100%', textAlign: 'center', padding: '4.5% 0', color: '#0A7AFF'}}>
            {(voted === "true")
                ? <IoIosHeart size='3em'/>
                : <IoIosHeartEmpty size='3em'/>
            }
        </div>

    );
}

export default ARBannerButtonScreen;